import React from 'react';
import PropTypes from 'prop-types';
import TilesList from '../TilesList';
import Tile from '../TilesList/Tile';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledArticlesList = styled.div`
	margin: 88px auto 50px;
`;

const ArticlesList = (props) => {
	return (
		<StyledArticlesList>
			<TilesList>
				{
					props.articles.map(({ node }) => (
						<Tile
							key={node.id}
							id={node.id}
							tag={Link}
							img={node.featured_media.localFile}
							text={node.excerpt.replace(/<\/?p>/g, '')}
							title={node.title}
							slug={node.slug}
						/>
					))
				}
			</TilesList>
		</StyledArticlesList>
	);
};

ArticlesList.propTypes = {
	articles: PropTypes.array,
};

export default ArticlesList;
