import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SubpageTopCover from '../components/SubpageTopCover';
import PageLayout from '../layouts/PageLayout';
import { useContactPicture } from '../hooks/use-contact-picture';
import { blog } from '../seoConfig';
import ArticlesList from '../components/BlogPage/ArticlesList';

const Contact = ({ location, data }) => {
	const img = useContactPicture();
	return (
		<PageLayout location={location} seoConfig={blog}>
			<SubpageTopCover backgroundImg={img}>Blog</SubpageTopCover>
			<ArticlesList articles={data.allWordpressPost.edges} />
		</PageLayout>
	);
};

export const pageQuery = graphql`
	query IndexQuery {
      allWordpressPost{
          edges {
              node{
                  id
                  slug
                  excerpt
									title
                  featured_media{
											localFile{
												childImageSharp {
														fixed(width: 400, quality: 80) {
																...GatsbyImageSharpFixed_withWebp
														}
												}
											}
                  }
              }
          }
      }
	}
`;
// export const pageQuery = graphql`
// 	query IndexQuery {
// 		allStrapiArticle {
// 			edges {
// 				node {
// 					id
// 					title
// 					content
// 					hero_image{
// 						childImageSharp {
// 							fluid(maxWidth: 1920, quality: 80) {
// 								...GatsbyImageSharpFluid_withWebp
// 							}
// 						}
// 					}
// 					author {
// 						id
// 						username
// 					}
// 				}
// 			}
// 		}
// 	}
// `;
Contact.propTypes = {
	location: PropTypes.object,
	data: PropTypes.object,
};
export default Contact;
